import HttpRequest from '@/resources/axios/core/http-request'
import store from '@/store'

class LogProvider extends HttpRequest {
  getLogList = (params) => {
    this.setHeader({
      key: 'Authorization',
      value: `Bearer ${store.getters['auth/user'].accessToken}`
    })
    return this.get(`/logs?page=${params.page}&limit=${params.limit}&orderBy=id&sortBy=desc`)
  }
}

export default new LogProvider()
