<template>
  <div class="log">
    <v-row class="mb-4">
      <v-col
        cols="12"
        md="8">
        <h1> {{ $t('app.page.log.title') }} </h1>
      </v-col>
    </v-row>
    <v-data-table
      :loading="isLoading"
      :headers="headers"
      :items="items"
      :loading-text="$t('vuetify.loading')"
      :no-data-text="$t('vuetify.noData')"
      hide-default-footer>
      <template v-slot:item.method="{ item }">
        <v-chip
          :color="colorMethod(item.meta.reqMethod)"
          small>
          {{ item.meta.reqMethod }}
        </v-chip>
      </template>
      <template v-slot:item.uri="{ item }">
        <div class="log__uri">
          {{ item.meta.reqUri }}
        </div>
      </template>
    </v-data-table>
    <div class="d-flex justify-center justify-md-end mt-6">
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="5" />
    </div>
  </div>
</template>

<script>
import LogProvider from '@/resources/axios/providers/log'

export default {
  name: 'Log',
  data () {
    return {
      page: 1,
      limit: 7,
      totalPage: 1,
      isLoading: false,
      headers: [
        { text: this.$t('app.table.header.ip'), sortable: false, value: 'meta.remoteIp' },
        { text: this.$t('app.table.header.method'), sortable: false, value: 'method' },
        { text: this.$t('app.table.header.uri'), sortable: false, value: 'uri' }
      ],
      items: []
    }
  },
  watch: {
    page () {
      this.getLog()
    }
  },
  mounted () {
    this.getLog()
  },
  methods: {
    colorMethod (method) {
      if (method === 'GET') {
        return 'success'
      }
      if (method === 'POST') {
        return 'primary'
      }
      return 'error'
    },
    async getLog () {
      try {
        this.isLoading = true
        const res = await LogProvider.getLogList({
          page: this.page,
          limit: this.limit
        })
        if (res) {
          this.isLoading = false
          this.items = res.records
          this.totalPage = res.totalPage
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .log {
    padding: 16px 32px;
  }
  .log__tag {
    max-width: 100px;
    display: flex;
    justify-content: center;
  }
  .log__uri {
    width: 100%;
  }

@media only screen and (max-width: 600px) {
  .log__uri {
    width: 150px;
    word-break: break-word;
  }
}
</style>
